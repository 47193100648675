<template>
  <div>
    <b-card
      class="card-custom"
      title="Update diapps APK"
      :sub-title="`Please complete to form below to publish an update for diapps`"
      sub-title-tag="p"
    >
      <hr class="mt-7" />

      <b-form @submit.prevent="onSubmit">
        <div class="row">
          <b-form-group class="mb-0 col-md-4" label="APK:">
            <b-input-group>
              <b-form-file
                class="mt-0 mb-3"
                v-model="form.apk"
                :disabled="isSubmitting"
                accept=".apk"
                :state="Boolean(form.apk)"
                placeholder="Choose an apk file"
                drop-placeholder="Drop file here..."
                required
              >
                <template slot="file-name" slot-scope="{ names }">
                  <b-badge variant="dark">{{ names[0] }}</b-badge>
                </template></b-form-file
              >
            </b-input-group>
          </b-form-group>

          <div class="col-md-12 mb-3"></div>
          <b-form-group class="mt-0 mb-3 col" label="Description:">
            <b-form-textarea
              required
              :disabled="isSubmitting"
              v-model="form.description"
              placeholder="Enter your application description"
              rows="3"
              max-rows="8"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col mt-5">
          <b-button type="submit" ref="submitApplication" variant="primary"
            >Update <i class="la la-check"></i
          ></b-button>
        </div>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { humanFileSize } from "@/utils";
import { UPDATE_APPLICATION } from "@/core/services/store/update.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

import { getObjectDifferences } from "@/utils";

export default {
  mixins: [validationMixin],
  data() {
    return {
      isUploadNewAPK: false,
      humanFileSize,
      isDownloading: false,
      isSubmitting: false,
      form: {
        description: null,
        apk: null,
      },
    };
  },
  created() {},
  validations: {
    form: {
      appName: {
        required,
        minLength: minLength(2),
      },
      description: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    onSubmit() {
      if (this.isSubmitting) return;
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }

      this.isSubmitting = true;
      // set spinner to submit button
      const submitButton = this.$refs["submitApplication"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const formData = new FormData();
      formData.append("description", this.form.description);
      formData.append("userId", this.currentUser._id);
      formData.append("apk", this.form.apk);

      this.$store
        .dispatch(UPDATE_APPLICATION, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        // go to which page after successfully login
        .then((a) =>
          this.$bvToast.toast(
            "Your application has successfully been updated",
            {
              title: "Successfully submitted",
              variant: "success",
              solid: true,
              appendToast: true,
            }
          )
        )
        .catch((e) => {
          this.$bvToast.toast(
            e || "Failed to update to update application details.",
            {
              title: "Submission failed",
              variant: "danger",
              solid: true,
              appendToast: true,
            }
          );
        })
        .finally(() => {
          this.isSubmitting = false;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>